<template>
  <div class="addUser">
    <div class="item name">
      <div class="item-l">姓名</div>
      <div class="item-r">
        <v-input
          v-model="form.name"
          inputAlign="right"
          :maxlength="10"
          placeholder="请填写姓名"
        />
      </div>
    </div>
    <div class="item sex">
      <div class="item-l">性别</div>
      <div class="item-r" style="margin-left: 16px">
        <v-radio-group
          :radioObj="sexOps"
          :value.sync="form.sex"
        ></v-radio-group>
      </div>
    </div>
    <div class="item familyType">
      <div class="item-l">家人类型</div>
      <div class="item-r" style="margin-left: 16px">
        <v-radio-group
          :radioObj="familyTypeOps"
          :value.sync="form.type"
        ></v-radio-group>
      </div>
    </div>
    <div class="item memberCode">
      <div class="item-l">身份证号码</div>
      <div class="item-r">
        <v-input
          type="number"
          inputAlign="right"
          :maxlength="18"
          v-model="form.code"
          placeholder="请填写身份证号码"
        />
      </div>
    </div>
    <div class="operaBtn" @click="save">
      <div class="submit">保存</div>
    </div>
  </div>
</template>

<script>
import { addFamilyMemberUrl, addPeopleDetailUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "addFamilyMember",
  data() {
    return {
      form: {
        name: "", // 成员姓名
        sex: "", // 成员性别 1男 2女
        code: "", // 成员身份证号
        type: undefined, // 家庭类型：1孩子 2父母
      },
      familyTypeOps: [
        { name: 1, value: "孩子" },
        { name: 2, value: "父母" },
      ],
      sexOps: [
        { name: 1, value: "男" },
        { name: 2, value: "女" },
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  destroyed() {},
  methods: {
    save() {
      if (!this.vaild()) {
        return;
      }
      this.saveRequest();
    },
    vaild() {
      console.log(this.form);
      if (!this.form.name) {
        this.$toast({ message: "请填写姓名" });
        return;
      }
      if (!this.form.sex) {
        this.$toast({ message: "请选择性别" });
        return;
      }
      if (!this.form.type) {
        this.$toast({ message: "请选择家人类型" });
        return;
      }
      if (!this.form.code) {
        this.$toast({ message: "请输入身份证号码" });
        return;
      }
      const cardCodeRegExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!cardCodeRegExp.test(this.form.code)) {
        this.$toast("身份证号格式错误");
        return;
      }
      return true;
    },
    saveRequest() {
      let params = {
        ...this.form,
        userId: this.userId,
      };
      this.$axios.post(addFamilyMemberUrl, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "操作成功", duration: 300 });
          // this.$router.replace({
          //   name: "myFamilyList",
          // });
          this.$router.go(-1);
        }
      });
    },
    // getdetail() {
    //   let params = {
    //     memberId: this.memberId,
    //     userId: this.userId,
    //   };

    //   this.$axios.get(addPeopleDetailUrl, { params }).then((res) => {
    //     if (res.code == 200) {
    //       this.form.name = res.data.applicantsName;
    //       this.form.sex = res.data.applicantsSex;
    //       this.form.code = res.data.memberCode;
    //       this.form.type = res.data.familyType;
    //       // this.form.applicantsPhone = res.data.applicantsPhone;
    //       // this.form.applicantsBirthday = res.data.applicantsBirthday;
    //       // this.form.applicantsRelation = res.data.applicantsRelation;
    //       // this.form.relation = getOption(
    //       //   res.data.applicantsRelation,
    //       //   relationList,
    //       //   "key"
    //       // ).value;
    //       console.log(this.form, 322);
    //     }
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.addUser {
  // overflow: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  color: #1b1c33;
  font-size: 32px;
  padding: 32px;
  padding-bottom: calc(132px + constant(safe-area-inset-bottom));
  padding-bottom: calc(132px + env(safe-area-inset-bottom));
  .arrow::after {
    position: absolute;
    content: "";
    width: 32px;
    height: 32px;
    background: url(./img/tip.png) no-repeat;
    background-size: 100% 100%;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .headImg {
    padding: 25px 0;
    position: relative;
    display: flex;
    padding-right: 32px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .item-l {
      font-size: 32px;
    }
    .head {
      width: 80px;
      height: 80px;
      vertical-align: middle;
      border-radius: 50%;
    }
  }
  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .item-l {
      font-size: 32px;
      font-weight: 400;
      color: #323334;
    }
    .item-r {
      flex: 1;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      input {
        height: 100%;
      }
    }
  }
  .sex,
  .family,
  .familyType {
    padding: 26px 0;
  }
  .operaBtn {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .submit {
      width: 550px;
      height: 66px;
      font-size: 28px;
      line-height: 66px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
    }
    // width: 100%;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // margin: auto;
    // height: 96px;
    // background: #007eff;
    // font-size: 32px;
    // line-height: 96px;
    // text-align: center;
    // color: #fff;
  }
}
</style>
<style lang="less">
.addUser {
  .van-uploader__upload,
  .van-uploader__preview-image,
  .van-uploader__file {
    width: 80px;
    height: 80px;
  }

  .van-radio-group {
    justify-content: flex-end;
  }
}
</style>
